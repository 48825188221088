<template>
  <div :class="['modal',{'is-active': active}]">
    <div class="modal-background" @click="close"></div>
    <slot></slot>
    <button v-if="closable" class="modal-close is-large" aria-label="close" @click="close"></button>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue'
const emit = defineEmits(["close"])
const props = defineProps<{
  active?: boolean,
  closable?: boolean
}>()
onBeforeMount(() => {
  document.addEventListener('keydown', (event) => {
    if(event.key == "Escape") {
      close()
    }
  });
})
function close() {
  emit("close")
}
</script>
